import Repo from './_repo.js'
import ScopesCreate from '@/actions/scopes/create.js'

let indexingPromise = null
let boardDataCache = {}
let projectRegistry = {}
let projectsCache = null

// const STATUS_ACTIVE = 0
// const STATUS_ARCHIVED = 1
// const STATUSES =  Object.freeze({
//   'active': STATUS_ACTIVE,
//   'archived': STATUS_ARCHIVED
// })

// During testing, the average project takes around 300 KB + temporary data (e.g. missing cards).
// Trello's limit is 8000 KB.
// const MAX_PROJECT_COUNT = 8

export default class extends Repo {
  static create(data, t) {
    // // Validations
    // const projectCount = Object.keys(projectRegistry).length
    // if (projectCount >= MAX_PROJECT_COUNT) {
    //   return Promise.reject(new Error(`Cannot have more than ${MAX_PROJECT_COUNT} active projects`))
    // }

    const projectId = data.id
    const projectName = data.name
    if (!projectId || !projectName) {
      return Promise.reject(new Error("Incomplete data"))
    }

    const timestamp = new Date().getTime()
    // Don't save static project attributes locally. They can come from the server.
    const project = {
      id: projectId,
      // name: projectName,
      scopeCardIds: [],
      missingCards: {},
      // status: STATUS_ACTIVE,
      // createdAt: timestamp,
      updatedAt: timestamp
    }

    return this.setBoardData({
      [$tpu.keys.brdProjectId(projectId)]: project,
    }, t).then(() => {
      projectRegistry[projectId] = project
      const name = "Unscoped"
      const description = "At the beginning of a project, it is not always clear how to group the " +
        "individual pieces of work, known as tasks. You can place these tasks within the 'Unscoped' " +
        "scope until you have gained enough orientation to determine which tasks belong to specific scopes.\n\n" +
        "For more recommendations, please refer to the Guide page which can be accessed via the toolbar " +
        "located at the top of the Shape Up Dashboard."
      if (this.isInTrello()) {
        return ScopesCreate.promise(name, projectId, t, null, description).then((scopeCard) => {
          // So first time users notice this feature
          $tpu.r.cards.setData(scopeCard.id, { [$tpu.keys.crdRiskLevel()]: 1 }, t)
          return scopeCard
        }).then((scopeCard) => {
          return [project, scopeCard]
        })
      }
      return Promise.resolve([project, { id: 'unscoped', name: name, desc: description }])
    })
  }

  static async freshUpdate(projectId, t, updater, disableTouch) {
    const project = await this.fetch(projectId, t) // Ensure we are working with the latest data.
    if (project) {
      // const updatedData = Object.assign(project, params, { updatedAt: timestamp })
      // const updatedData = Object.assign(updater(project), { updatedAt: timestamp })
      updater(project)

      if (!disableTouch) {
        const timestamp = new Date().getTime()
        Object.assign(project, { updatedAt: timestamp })
      }

      await $tpu.r.data.set(t, "board", "shared", $tpu.keys.brdProjectId(projectId), project)

      // It's important to save the data to the cache because the object might be
      // used in existing components for rendering.
      const cachedProject = projectRegistry[projectId]
      Object.assign(cachedProject, project)

      // await this._rawUpdate(projectId, project, t, disableTouch)

      return project
    }
    return null
  }

  // static async update(projectId, params, t) {
  //   const timestamp = new Date().getTime()
  //   const project = await this.fetch(projectId, t) // Ensure we are working with the latest data.
  //   if (project) {
  //     const updatedData = Object.assign(project, params, { updatedAt: timestamp })

  //     await this.rawUpdate(projectId, updatedData, t, true)

  //     // It's important to save the data to the cache because the object might be
  //     // used in existing components for rendering.
  //     const cachedProject = this.find(projectId)
  //     Object.assign(cachedProject, updatedData)

  //     return project
  //   }
  //   return null
  // }

  // NOTE: Be careful when using this method. This method does not take params, but instead
  // it takes a complete new projectData to replace the old data.
  // static _rawUpdate(projectId, projectData, t, disableTouch) {
  //   const timestamp = new Date().getTime()
  //   let newData
  //   if (disableTouch) {
  //     newData = projectData
  //   } else {
  //     newData = Object.assign(projectData, { updatedAt: timestamp })
  //   }

  //   return $tpu.r.data.set(t, "board", "shared", $tpu.keys.brdProjectId(projectId), newData)
  // }

  static async destroy(projectId, t) {
    // const projectIds = await $tpu.r.data.get(t, "board", "shared", $tpu.keys.brdProjectIds(), [])
    // projectIds.remove(projectId)

    // await this.setBoardData({
    //   [$tpu.keys.brdProjectIds()]: projectIds,
    //   [$tpu.keys.brdProjectId(projectId)]: null // Null out the data first so the core deletion process is atomic.
    // }, t)

    // delete projectRegistry[projectId]

    // Null out first so the board's updatedAt gets updated.
    // await this.setBoardData({
    //   [$tpu.keys.brdProjectId(projectId)]: null
    // }, t)

    // Remove the nulled-out data to recoup storage space.
    await $tpu.r.data.remove(t, "board", "shared", $tpu.keys.brdProjectId(projectId))

    delete projectRegistry[projectId]
  }

  // Right now we only update the board's updatedAt when adding/removing projects. If needed, this can be extended
  // in the future to support other board operations.
  static setBoardData(params, t) {
    const timestamp = new Date().getTime()
    const newData = Object.assign({ updatedAt: timestamp }, params)

    // Update the local cache for immediate availability.
    Object.assign(boardDataCache, newData)

    // Only save the new data (not the whole boardData) to prevent data loss due to concurrent updates.
    // See https://developer.atlassian.com/cloud/trello/power-ups/client-library/getting-and-setting-data/
    return $tpu.r.data.set(t, "board", "shared", newData)
  }

  static _all(t) {
    return $tpu.r.data.get(t, "board", "shared").then((boardData) => {
      boardDataCache = boardData

      const array = []
      for (const [key, value] of Object.entries(boardData)) {
        if (key.startsWith("p:")) {
          const id = Number(key.replace(new RegExp(`^p:`),''))
          if (GLib.type.isNumber(id)) { // Confirmed a DB PK
            // $tpu.r.data.remove(t, "board", "shared", key)

            const project = value
            project.id = id

            // In theory, the project should always exist since project data is created/removed atomically,
            // but we need to be extra defensive when it comes to frontend logics.
            // project.isArchived = project.status == STATUS_ARCHIVED

            this._augmentData(project, array)
            array.push(project)
          }

        }
      }
      return array
    })
    // return $tpu.r.data.get(t, "board", "shared").then((boardData) => {
    //   boardDataCache = boardData

    //   const projectIds = boardData[$tpu.keys.brdProjectIds()] || []
    //   const array = []
    //   projectIds.forEach((projectId, index) => {
    //     const project = boardData[`p:${projectId}`]

    //     // In theory, the project should always exist since project data is created/removed atomically,
    //     // but we need to be extra defensive when it comes to frontend logics.
    //     if (project) {
    //       project.isArchived = project.status == STATUS_ARCHIVED
    //       this._augmentData(project, index)
    //       array.push(project)
    //     }
    //   })
    //   return array
    // })
  }

  // static _allIds(t) {
  //   return $tpu.r.data.get(t, "board", "shared", $tpu.keys.brdProjectIds(), [])
  // }

  static _augmentData(project, array) {
    // project.index = index
    // project.idTag = `P${index + 1}`
    // project.chartSnapshots = project.chartSnapshots || []


    // Populate test data
    if (!this.isInTrello()) {
      const index = array.length
      switch(index) {
        case 0: {
          // Note: This includes all Scope cards even though in the beginning not all of them are visible. Refer to the setTimeout() call in `cards#_all`.
          // This means that the project's scopeCardIds will keep getting truncated (hence `updatedAt` keeps increasing) until all the scope cards become visible.
          project.scopeCardIds = ['story1', 'story4', 'story6', 'story7']
          break;
        }
        case 1: {
          project.scopeCardIds = ['story2', 'story3']
          break;
        }
        case 2: {
          project.scopeCardIds = ['story5']
          break;
        }
        default: {
          // No scope cards
          break;
        }
      }
    }
  }

  static registry(t) {
    if (indexingPromise) {
      // console.debug("Reusing project indexing promise...")
      return indexingPromise
    }

    // console.debug("Fetching new indexed projects...")

    indexingPromise = new Promise((resolve, reject) => {
      const indexedProjects = {}
      this._all(t).then((projects) => {
        projects.forEach((project) => {
          indexedProjects[project.id] = project
        })

        projectRegistry = indexedProjects
        resolve(indexedProjects)

        // Let the promise be reused by other requests that were made within X seconds.
        setTimeout(() => {
          indexingPromise = null
        }, 1000)
      })
    })
    return indexingPromise
  }

  static cache(projectCores) {
    const frozenCache = projectRegistry

    const currentTimestamp = new Date().getTime()
    let lastUpdatedAt = boardDataCache.updatedAt

    const array = []
    projectCores.forEach((projectCore) => {
      const projectExtra = frozenCache[projectCore.id]
      // const projectExtra = null
      const project = {}
      if (projectExtra) {
        Object.assign(project, projectCore, projectExtra)

        if (project.updatedAt > lastUpdatedAt) {
          lastUpdatedAt = project.updatedAt
        }
      } else {
        console.warn("Project doesn't exist locally", projectCore.id)

        // This may happen when:
        // 1) The second phase of project deletion (i.e. server-side) failed. In this case we want to allow the user another
        //    chance to delete the project.
        // 2) The user did a hard-uninstall of the Power-Up and then reinstalled it.
        Object.assign(project, projectCore, { dataMissing: true })
      }

      array.push(project)
    })

    const existingCache = projectsCache
    let latestCache = existingCache
    if (!existingCache || lastUpdatedAt > existingCache._lastUpdatedAt) {
      latestCache = { data: array, lastRefreshedAt: currentTimestamp, _lastUpdatedAt: lastUpdatedAt }
      projectsCache = latestCache
    }

    return latestCache
  }

  // // `coreData` can be null. It will have no effect on the returned object.
  // static find(projectId, coreData) {
  //   // Return the cached object (not a separate object) as it is the most up-to-date authority.
  //   return Object.assign(projectRegistry[projectId], coreData)
  // }

  static fetch(projectId, t) {
    return $tpu.r.data.get(t, "board", "shared", $tpu.keys.brdProjectId(projectId))
  }

  static async lastViewed(t) {
    if (this.isInTrello()) {
      const [registry, settings] = await Promise.all([
        $tpu.r.projects.registry(t),
        $tpu.r.data.getMemberBoardData(t)
      ])
      if (settings) {
        const projectId = settings.projectId
        return registry[projectId]
      }
    }

    // It's easier to always test from the beginning.
    return null
  }

  static clearCache() {
    projectsCache = null
  }
}
